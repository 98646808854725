import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import FullProjectList from '../components/full-project-list'
import { Link } from 'gatsby'

import { graphql } from 'gatsby'

const ProjectsPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const projects = edges
    .filter(edge => edge.node.frontmatter.project)
    .filter(edge => edge.node.frontmatter.summary_image != null)

  const pageTitle = `All ${projects.length} Projects`
  return (
    <Layout pageTitle={pageTitle}>
      <SEO title={pageTitle} />
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className="is-active">
            <a href="/projects" aria-current="page">
              Projects
            </a>
          </li>
        </ul>
      </nav>
      <FullProjectList projects={projects} />
    </Layout>
  )
}

export default ProjectsPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [fields___date] }
      filter: { frontmatter: { project: { eq: true } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            title
            project
            summary_image
          }
          fields {
            path
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
