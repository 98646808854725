import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

const FullProjectList = ({ projects }) => (
  <div className="columns is-multiline">
    {projects.map(({ node }) => (
      <div key={node.id} className="column is-one-third">
        <div className="card">
          <header className="card-header">
            <p className="card-header-title">
              <Link to={`/${node.fields.path}`}>{node.frontmatter.title}</Link>
            </p>
          </header>
          <div className="card-image">
            <Link to={`/${node.fields.path}`}>
              <figure className="image is-square">
                <img src={node.frontmatter.summary_image} alt="Summary" />
              </figure>
            </Link>
          </div>
          <div className="card-content">
            <div className="content">{node.excerpt}</div>
          </div>
        </div>
      </div>
    ))}
  </div>
)

FullProjectList.propTypes = {
  projects: PropTypes.any,
}

FullProjectList.defaultProps = {
  projects: [],
}

export default FullProjectList
